/*
 * @version: 1.0
 * @Date: 2023-05-17 17:43:01
 * @LastEditors: 杨慢慢
 */
//审批节点默认属性
export const APPROVAL_PROPS = {
  assignedType: "ASSIGN_USER", // LEADER_TOP 连续多级主管 // LEADER 
  sign: false,
  nobody: {
    handler: "TO_PASS", // TO_ADMIN
  },
  repeatPass: false,
  timeLimit:{
    timeout:{
      unit: "H",
      value: 0
    },
    handler:{
      type: "REFUSE",
      notify:{
        once: true,
        hour: 1
      }
    }
  },
  mode: "AND",
  leaderTop: {
    endCondition:"LEVEL",
    endLevel: 1
  }, // 连续多级主管
  leader: { level: 1}, // 主管
  type: "APPROVAL",
  assignedUser:[], // 成员列表
  formPerms:[],
  selfSelect: {
    multiple: false
  },
  role:[],
  refuse: {
    type: 'TO_END', //驳回规则 TO_END  TO_NODE  TO_BEFORE
    target: '' //驳回到指定ID的节点
  },
  formUser: ''
}

//根节点默认属性
export const ROOT_PROPS = {
  assignedUser: [],
  formPerms:[]
}

//条件节点默认属性
export const CONDITION_PROPS = {
  // isGroups: false, // 是否设置了条件
  groupsType: "OR", //条件关系 OR、AND
  groups:[
    {
      groupType: "OR",
      cids:[], //条件ID集合
      conditions:[
        {
          id: 'root',
          title: '发起人', // 条件 1发起人 2金额 3数值
          valueType: "User",
          compare: "", // 符号
          value: [] // 发起人或数字
        }
      ] //组内子条件
    }
  ],
}

export default {
  APPROVAL_PROPS, CONDITION_PROPS, ROOT_PROPS
}
